import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import Button from '@shared/ui/buttons/Button';
import ItemsList from '@shared/ui/display/ItemsList';
import { StepContainer, Title, VerificationBanner } from '@widgets/sidebars/shared';
import { BodyMediumBold } from '@src/components/styled/Typography';
const ButtonsContainer = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: `${props.theme.spacing_sizes.m}px`,
}));
const VerificationBannerTitle = styled(BodyMediumBold)(props => ({
    color: props.theme.palette.text.disabled,
}));
const GetVerification = ({ goToNextStep, skip, isCreating }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const handlePrimaryBtnClick = () => {
        if (goToNextStep) {
            goToNextStep();
        }
    };
    const handleSecondaryBtnClick = () => {
        if (skip) {
            skip();
        }
    };
    return (_jsx(StepContainer, { title: (_jsx(Title, { children: t('profile.create_trader.steps.get_verification.title', { ns: 'common' }) })), content: (_jsx(VerificationBanner, { title: (_jsx(VerificationBannerTitle, { children: t('profile.create_trader.steps.get_verification.list_title', { ns: 'common' }) })), content: (_jsx(ItemsList, { list: t('profile.create_trader.steps.get_verification.list', { returnObjects: true, ns: 'common' }) || [], listStyle: 'check-icon-solid' })) })), contentFlex: 1, contentJustify: 'start', containerGap: theme.spacing_sizes.xs * 5, buttons: (_jsxs(ButtonsContainer, { children: [_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: handlePrimaryBtnClick, disabled: true, children: t('profile.create_trader.steps.get_verification.continue_btn_text', { ns: 'common' }) }), _jsx(Button, { variant: 'tinted', color: 'primary', size: 'large', fullWidth: true, onClick: handleSecondaryBtnClick, isLoading: isCreating, disabled: isCreating, children: t('profile.create_trader.steps.get_verification.skip_btn_text', { ns: 'common' }) })] })) }));
};
export default GetVerification;
